
<div class="container">
<div class="card">
<h1>Payment Cancelled</h1>
<p>It seems like the payment process was not completed. If you encountered any issues, please try again or contact our support team.</p>
<div class="btn-group">
<a  class="btn" (click)="onPageChange()">Pay Later</a>
<a class="btn secondary" (click)="CancelAppointment()">Cancel Appointment</a>
</div>
</div>
</div>




 