import { BlogDetailComponent } from "./blog/blog-detail/blog-detail.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
// import {loginAuthgardComponent} from "./services/data-service/auth-gard-service";
// import { VerificationComponent } from './patient/verification/verification.component';
// import { DepartmentVisitComponent } from './department-visit/department-visit.component';
// import { EmailVerificationComponent } from './email-verification/email-verification.component'
// import { Component } from '@angular/core';
// import {PracticeDataResolver} from "./services/data-service/practice-resolver";

const routes: Routes = [
  {
    path: "IframeUrl",
    component: IframeUrlComponent,
  },
  {
    path: "cancelUrl",
    component: PaymentCancelComponent,
  },
  
  {
    path: "",
    loadChildren: () =>
      import("./web-layout/web-layout.module").then((m) => m.WebLayoutModule),
  },
  {
    path: ":practiceN/patient",
    loadChildren: () =>
      import("./patient/patient.module").then((m) => m.PatientModule),
  },
  {
    path: ":practiceN/apt",

    loadChildren: () =>
      import("./landing-page/landing-page.module").then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: "patient/User",
    loadChildren: () =>
      import("./authrized-page/authrized-page.module").then(
        (m) => m.AuthrizedPageModule
      ),
  },
  {
    path: "biz/:name",
    pathMatch: "full",
    loadChildren: () =>
      import(
        "./web-layout/web-page/searchresultdeatils/searchresultdetails.module"
      ).then((m) => m.BusinessLocationModule),
  },
  {
    path: ":foldername/portal",
    // pathMatch: "full",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
    canActivateChild:[PatientPortalAuthguardComponent]
  },
  {
    path:"home", 
    loadChildren:()=> import('./patient-portal/patient-portal.module').then(m=> m.PatientPortalModule),
    canActivateChild:[PatientPortalRouteGuard]
  },
  {
    path: ":practiceN/:name",
    loadChildren: () =>
      import("./appointment/appointment.module").then(
        (m) => m.AppointmentModule
      ),
  },
 
  
  {
    path: "**",
    redirectTo: "",
  },
];

import { PreloadingStrategy, Route } from "@angular/router";
import { Observable, of } from "rxjs";
import { PatientPortalAuthguardComponent } from "./services/data-service/patient-portal-Auth-guard.service";
import { PatientPortalRouteGuard } from "./services/data-service/patient-portal-router-graud.sevice";
import { IframeUrlComponent } from "./patient-portal/iframe-url/iframe-url.component";
import { PaymentCancelComponent } from "./patient-portal/payment-cancel/payment-cancel.component";

export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return route.data && route.data["preload"] ? load() : of(null);
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategy,
    }),
  ], //, { enableTracing: true }
  exports: [RouterModule],
  providers: [CustomPreloadingStrategy],
})
export class AppRoutingModule {}
