import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {loginAuthgardComponent} from "./services/data-service/auth-gard-service";
import {HttpConfigInterceptor} from "./services/http/http-interceptor";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AccordionModule} from 'primeng/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { IAppointmentMTService } from './services/interface/appointment/i.appointment-method';
import { AppointmentService } from './services/api-services/Appointment';
import { DepartmentVisitComponent } from './department-visit/department-visit.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component'
import {IAccountService} from './services/interface/account/i.account-method';
import {AccountService} from './services/api-services/accout';
import {InstantUCWebLoaderModule} from './share/component/loader.component'
import { IframeUrlComponent } from './patient-portal/iframe-url/iframe-url.component';
import { PaymentCancelComponent } from './patient-portal/payment-cancel/payment-cancel.component';

@NgModule({
  declarations: [
    AppComponent,
    DepartmentVisitComponent,
    EmailVerificationComponent,
    IframeUrlComponent,
    PaymentCancelComponent
 ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastModule,
    ProgressSpinnerModule,
    FormsModule,
     ReactiveFormsModule,
     InstantUCWebLoaderModule
  ],
  providers: [
    loginAuthgardComponent,
    { provide: IAppointmentMTService, useClass: AppointmentService },
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    {
      provide:IAccountService,useClass:AccountService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

