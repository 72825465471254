import { GlobalService } from '@Globalservice';
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from '@angular/core';
import { IAppointmentMTService } from 'src/app/services/interface/appointment/i.appointment-method';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'Instant-UC-payment-cancel',
  templateUrl: './payment-cancel.component.html',
  styleUrls: ['./payment-cancel.component.scss']
})
export class PaymentCancelComponent implements OnInit {
  Details:any
  constructor(private activatedRoute:ActivatedRoute,private iAppointmentMTService:IAppointmentMTService,
    private router:Router,private globalService:GlobalService
  ) {
   
    this.activatedRoute.queryParams.subscribe(item=>{
      this.Details=item
    })
   }

  ngOnInit(): void {
  }

  CancelAppointment() {
      let data = {
        Username: '',
        PracticeId: this.Details.PracticeId,
        DepartmentId: this.Details.DepartmentId,
        AppointmentId: this.Details.appointmentId,
        PatientId: this.Details.patientId,
        CancellationReason: 'Payment Cancel',
        AppointmentTypeId: this.Details.appointmenttypeid,
      };
      this.iAppointmentMTService.CancelAppointment(data).subscribe(
        (res) => {
          this.router.navigateByUrl(`/home/patient-portal-dashboard`);
        },
        (error) => { }
      );
  }

  onPageChange() {
    this.globalService.bookStep=4;
  }
}
